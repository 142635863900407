/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Optima';
    font-style: normal;
    font-weight: normal;
    src: local('Optima'), url('OPTIMA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Optima Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Optima Italic'), url('Optima_Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Optima Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Optima Medium'), url('Optima Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Optima Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Optima Bold'), url('OPTIMA_B.woff') format('woff');
    }