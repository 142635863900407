body {
  margin: 0;
  background-color: var(--color-black) !important;
  color: #f8b04a !important;
  font-family: "optima-regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-golden: #f8b04a;
  --color-black: #0c0c0c;
}
.button {
  border: 1px solid #f8b04a !important;
  background-color: #f8b04a !important;
  color: #000 !important;
}

.button:hover {
  border: 1px solid #f8b04a;
  color: #f8b04a !important;
  background-color: transparent !important;
}
#foodItemNav li span {
  border-bottom: 1px solid transparent;
}

#foodItemNav .active {
  border-bottom: 1px solid #f8b04a !important;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  background-color: #fff !important;
}
#gallerySwiper img,
#GalleryList img {
  opacity: 1 !important;
  transition: 0.5s ease;
}
#gallerySwiper img:hover,
#GalleryList img:hover {
  opacity: 0.5 !important;
}
.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
}
#GalleryList::-webkit-scrollbar {
  background-color: #000 !important;
  width: 5px !important;
}
#GalleryList::-webkit-scrollbar-track {
  background-color: #000 !important;
}
#GalleryList::-webkit-scrollbar-thumb {
  background-color: #f8b04a !important;
  border-radius: 5px;
}

.iti--allow-dropdown {
  width: 100% !important;
  z-index: 1000000;
}

.iti__country-list {
  /* width: 100%; */
  border-radius: 5px;
  border: none;
  scroll-behavior: smooth;
  scrollbar-width: 5px !important;
  z-index: 10;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: transparent;
}

.iti--allow-dropdown .iti__flag-container:hover,
.iti__selected-flag {
  background-color: transparent !important;
}

#alert {
  display: none;
}
